<template>
  <evalmee-chip
    small
    :class="classes"
    :color="chipColor"
    label
    :dark="state === 'active'"
    :icon="icon"
    :icon-only="iconOnly"
    v-tooltip="iconOnly ? label : null"
  >
    <template v-if="!iconOnly">
      {{ label }}
    </template>
  </evalmee-chip>
</template>

<script>
import EvalmeeChip from "../shared/evalmee_chip.vue"

export default {
  name: "QuizStateBadge",
  components: { EvalmeeChip },
  props: {
    state: { type: String, required: true },
    classes: { type: String, default: "ma-2" },
    iconOnly: { type: Boolean, default: false },
  },
  computed: {
    chipColor: function () {
      const colorMap = {
        draft: "#757575",
        published: "blue",
        creating_instances: "blue",
        active: "green",
        paused: "orange",
        over: "red",
        data_deleted: "red",
        preview: "purple",
      }
      return colorMap[this.state]
    },
    icon() {
      return {
        draft: "mdi-pencil-outline",
        published: "mdi-rocket-outline",
        creating_instances: "mdi-rocket-outline",
        active: "mdi-rocket-launch-outline",
        paused: "mdi-pause",
        over: "mdi-checkbox-marked-outline",
        data_deleted: "mdi-checkbox-marked-outline",
        preview: "mdi-eye-outline",
      }[this.state]
    },
    label() {
      return this.$t("quiz.states." + this.state)
    },
  },

}
</script>

<style scoped>

</style>
